import React, {useEffect, useMemo, useState} from "react";
import {deleteEpigrafeApi, showEpigrafesApi} from "../../../../services/Admin/epigrafe";
import useAuth from "../../../../hooks/useAuth";
import TableFilter from "../../../../components/Admin/TableFilter";
import {Button, Grid, Paper} from "@mui/material";
import PopupDelete from "../../../../components/PopupDelete";
import {Delete, Edit} from "@mui/icons-material";
import MsgError from "../../../../components/MsgError";
import AsyncTableComp from "../../../../components/TableComponents/AsyncTableComp";
import InputText from "../../../../components/Inputs/InputText";

export default function Management() {
    const [epigrafes, setEpigrafes] = useState([])
    const [state, setState] = useState(initialState)
    const [quantity, setQuantity] = useState(0)
    const {logout} = useAuth()
    const [msgError, setMsgError] = useState([])
    const [popup, setPopup] = useState({
        show: false, // initial values set to false and null
        id: null,
    });

    const columns = useMemo(
        () => [
            {
                Header: "NOMBRE",
                accessor: "name",
                disableFilters: true
            },
            {
                Header: "ACCIONES",
                accessor: "actions",
                width: "200px",
                disableFilters: true
            }
        ],
        []
    )

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getEpigrafesData({limit: 100, offset: 0, ...state})
        }, 300);

        return () => clearTimeout(delayDebounceFn)
    }, [state])

    const getEpigrafesData =(params) => {
        showEpigrafesApi(logout, params)
            .then(data => {
                setEpigrafesData(data.data)
                setQuantity(Math.ceil(data.count / 100))
            })
            .catch(() => {
            });
    }

    const setEpigrafesData = (snapshot) => {
        const temp = snapshot.map((doc) => {
            const href = `/admin/epigrafes/edit/${doc.id}`

            return {
                actions: (
                    <Grid container>
                        <Grid item xs={6}>
                            <Button variant="text" color="primary" href={href}><Edit/></Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="text" onClick={() => {
                                handleDelete(doc.id)
                            }}><Delete/></Button>
                        </Grid>

                    </Grid>

                ),
                ...doc,
            }
        })
        setEpigrafes(temp)
    }

    const handleDelete = (id) => {
        setPopup({
            show: true,
            id,
        });
    };

    const handleDeleteTrue = () => {
        setMsgError([]);
        if (popup.show && popup.id) {
            deleteEpigrafeApi(logout, popup.id).then(_ => {
                getEpigrafesData()
            }).catch(error => {
                setMsgError([error.response.data.errors])
            })
            setPopup({
                show: false,
                id: null,
            });
        }
    };

    const handleDeleteFalse = () => {
        setPopup({
            show: false,
            id: null,
        });
    };

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    return (
        <>
            {popup.show && (
                <PopupDelete
                    show={popup.show}
                    handleDeleteTrue={handleDeleteTrue}
                    handleDeleteFalse={handleDeleteFalse}
                />
            )}
            <Grid item xs={6}>
                <h1>Epigrafes</h1>
            </Grid>
            <Grid item xs={6}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{float: "right", margin: "25px 0px"}}
                    href="/admin/epigrafes/create"
                >
                    + Añadir
                </Button>
            </Grid>
            <Grid item xs={12}>
                <MsgError msgError={msgError}/>
            </Grid>
            <Grid item xs={3}>
                <InputText
                    label="Nombre"
                    name="name"
                    value={state?.name}
                    onChange={handleChange}
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{display: 'flex', flexDirection: 'column'}}>
                    <AsyncTableComp
                        columns={columns}
                        data={epigrafes}
                        fetchDataApi={getEpigrafesData}
                        controlledPageCount={quantity}
                    />
                </Paper>
            </Grid>
        </>
    );
}

function initialState() {
    return {
        name: '',
    }
}
